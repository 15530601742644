import house1 from "./media/image1.png";
import house2 from "./media/image2.png";
import house3 from "./media/image3.png";

export const properties = [
  {
    id: "1",
    img: house1,
    price: "تحسين الأداء",
    address:
      "استمتع بمشيك مع مشد الركبة المتطور, مشد الركبة المتطور هو الحلاقة المثلى مشد الركبة المتطور",
    bedrooms: 100,
    bathrooms: 100,
    space: 100,
  },

  {
    id: "2",
    img: house2,
    price: "أداء أفضل",
    address:
      "استمتع بمشيك مع مشد الركبة المتطور, مشد الركبة المتطور هو الحلاقة المثلى مشد الركبة المتطور: رفيقك في التحسين المستمر للأداء",
    bedrooms: 100,
    bathrooms: 100,
    space: 100,
  },

  {
    id: "3",
    img: house3,
    price: "راحة أكبر",
    address:
      "استعد للعمل واللعب بقوة: الركبة المثلى مع مشد متطور احصل على دعم فائق: مشد الركبة المتطور يعني راحة غير مسبوقة",
    bedrooms: 4,
    bathrooms: 3,
    space: 3000,
  },
];
