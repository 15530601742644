import React, { useState } from "react";
import { TextField, Grid, Snackbar, Box } from "@mui/material";
import MuiAlert from "@mui/material/Alert";
import CustomButton from "./CustomButton";
import Alert from "@mui/material/Alert";
import emailjs from "emailjs-com";

import { styled } from "@mui/system";

const CustomTextField = styled(TextField)(({ theme }) => ({
  "& label.Mui-focused": {
    color: "#000", // تغيير لون label عند التحديد
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "#5b5b5b", // تغيير لون البوردر عند التحديد
    },
    "&:hover fieldset": {
      borderColor: "#67919B", // تغيير لون البوردر عند مرور المؤشر
    },
    "&.Mui-focused fieldset": {
      borderColor: "#004D56", // تغيير لون البوردر عند التحديد
    },
  },
}));

const ContactForm = () => {
  // const nodemailer = require("nodemailer");
  const [name, setName] = useState("");
  // const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [errorOpen, setErrorOpen] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();

    // Validate user inputs
    if (name  && message) {
      const subject = "Form Data";
      const body = `Name: ${name}\nPhoneNumber: ${message}`;
      const recipient = "hammsatalafaq@gmail.com";
      const mailtoLink = `mailto:${recipient}?subject=${subject}&body=${body}`;
      window.location.href = mailtoLink;

      // Reset form fields
      setName("");
      // setEmail("");
      setMessage("");
    } else {
      // Display an error message
      setErrorOpen(true);
    }
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setSnackbarOpen(false);
  };
  const handleWhatsapp = () => {
    const phoneNumber = "+966539397137";
    const message = "";

    const whatsappLink = `https://api.whatsapp.com/send?phone=${phoneNumber}&text=${encodeURIComponent(
      message
    )}`;
    window.open(whatsappLink, "_blank");
  };

  return (
    <Box
      sx={{
        padding: "16px",
        background: "#f5f5f5",
        borderRadius: "15px",
        width: { md: "500px", xs: "100%" },
        mb: 2,
        border: "solid",
      }}
    >
      <form>
        <Grid container spacing={2}>
          <br />
          <div
            style={{ textAlign: "center", margin: "auto" }}
            onClick={handleWhatsapp}
          >
            <CustomButton
              backgroundColor="#041f59"
              color="#fff"
              buttonText="whatsapp او ارسل رسالة مباشرة   "
              getStartedBtn={true}
            />
          </div>
          <Grid item xs={12}>
            <CustomTextField
              label="Name"
              value={name}
              onChange={(e) => setName(e.target.value)}
              fullWidth
              required
            />
          </Grid>
          {/* <Grid item xs={12}> */}
            {/* <CustomTextField
              label="Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              fullWidth
              required
            /> */}
          {/* </Grid> */}
          <Grid item xs={12}>
            <CustomTextField
              label="Phone Number"
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              multiline
              fullWidth
              required
            />
          </Grid>
          <Grid item xs={12} onClick={handleSubmit}>
            <CustomButton
              backgroundColor="#041f59"
              color="#fff"
              buttonText="Send"
              getStartedBtn={true}
            />
          </Grid>
        </Grid>
      </form>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleClose}
      >
        <MuiAlert
          onClose={handleClose}
          severity="success"
          elevation={6}
          variant="filled"
        >
          تم إرسال رسالتك بنجاح!
        </MuiAlert>
      </Snackbar>
      {errorOpen && (
        <Alert severity="error" onClose={() => setSnackbarOpen(false)}>
          يرجى ملء جميع الحقول!
        </Alert>
      )}
    </Box>
  );
};

export default ContactForm;
