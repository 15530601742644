import { Button, styled, Typography } from "@mui/material";
import { Box, Container, fontWeight } from "@mui/system";
import React from "react";
import homeIllustration from "../media/illustration.png";
import CustomButton from "./CustomButton";
import ContactForm from "./ContactForm";

const GetStarted = () => {
  const CustomContainer = styled(Container)(({ theme }) => ({
    backgroundColor: "#041f59",
    height: "416px",
    borderRadius: "15px",
    display: "flex",
    justifyContent: "space-around",
    alignItems: "center",
    [theme.breakpoints.down("md")]: {
      height: "auto",
      flexDirection: "column",
      alignItems: "center",
      padding: theme.spacing(3, 3, 0, 3),
      width: "90%",
    },
  }));

  const CustomBox = styled(Box)(({ theme }) => ({
    padding: theme.spacing(10, 0, 10, 0),
    margin: theme.spacing(0, 2, 0, 2),
    [theme.breakpoints.down("md")]: {
      padding: "0",
    },
  }));

  return (
    <div id="contact-section">
      <CustomBox>
        <CustomContainer>
          <Box>
            <Typography
              sx={{
                fontSize: "35px",
                color: "white",
                fontWeight: "700",
                textAlign: "center",
              }}
            >
              <p> اطلب الآن المشد الطبي المتطور</p>و استلم خلال أقل من 48 ساعة
            </Typography>
            {/* <Typography
              sx={{
                fontSize: "20px",
                color: "#ccc",
                fontWeight: "500",
                my: 3,
                textAlign: "end",
              }}
            >
              إملأ بياناتك ليتواصل معك المستشار الطبي خلال أقل من 6 ساعات
            </Typography> */}

            {/* <Box sx={{ display: "flex", textAlign: "center", margin: "auto" }}> */}
              <Typography
                sx={{
                  fontSize: "20px",
                  color: "#fff",
                  fontWeight: "500",
                  m: 3,
                  textAlign: "end",
                }}
              >
                سعر القطعة
                <span style={{ fontWeight: "bold" }}> (249 ريال)</span>
              </Typography>
              {/* <Typography
                sx={{
                  fontSize: "20px",
                  color: "#fff",
                  fontWeight: "500",
                  my: 3,
                  textAlign: "end",
                  ml: 2.2,
                }}
              >
                سعر 2 قطعة
                <span style={{ fontWeight: "bold" }}> (25$)</span>
              </Typography> */}
            {/* </Box> */}
            {/* <Box sx={{ display: "flex", textAlign: "center", margin: "auto" }}>
              <Typography
                sx={{
                  fontSize: "20px",
                  color: "#fff",
                  fontWeight: "500",
                  m: 3,
                  textAlign: "end",
                }}
              >
                سعر 4 قطع
                <span style={{ fontWeight: "bold" }}> (45$)</span>
              </Typography>

              <Typography
                sx={{
                  fontSize: "20px",
                  color: "#fff",
                  fontWeight: "500",
                  m: 3,
                  textAlign: "end",
                }}
              >
                سعر 8 قطع
                <span style={{ fontWeight: "bold" }}> (75$)</span>
              </Typography>
            </Box> */}

            <CustomButton
              backgroundColor="#fff"
              color="#041f59"
              buttonText="اطلب الان"
              getStartedBtn={true}
            />
          </Box>

          <ContactForm />
          <img
          src={homeIllustration}
          alt="illustration"
          style={{ maxWidth: "100%" }}
        />
        </CustomContainer>
      </CustomBox>
    </div>
  );
};

export default GetStarted;
