import { styled, Typography } from "@mui/material";
import { Box, Container } from "@mui/system";
import React from "react";
import houseCard from "../media/houseCard.png";

const Details = () => {
  const CustomBox = styled(Box)(({ theme }) => ({
    display: "flex",
    gap: theme.spacing(10),
    alignItems: "center",
    [theme.breakpoints.down("md")]: {
      flexDirection: "column",
      textAlign: "center",
    },
  }));

  const ImgContainer = styled(Box)(({ theme }) => ({
    width: "100%",
    [theme.breakpoints.down("md")]: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
  }));

  const LargeText = styled(Typography)(({ theme }) => ({
    fontSize: "45px",
    color: "#000",
    fontWeight: "700",
    [theme.breakpoints.down("md")]: {
      fontSize: "32px",
    },
  }));

  const SmallText = styled(Typography)(({ theme }) => ({
    fontSize: "20px",
    color: "#7B8087",
    fontWeight: "500",
    [theme.breakpoints.down("md")]: {
      fontSize: "14px",
    },
  }));

  const TextFlexbox = styled(Box)(({ theme }) => ({
    marginTop: theme.spacing(7),
    display: "flex",
    justifyContent: "space-between",
    padding: theme.spacing(0, 5, 0, 5),
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      gap: theme.spacing(5),
    },
  }));

  const Divider = styled("div")(({ theme }) => ({
    width: "13%",
    height: "5px",
    backgroundColor: "#004D56",
    [theme.breakpoints.down("md")]: {
      marginLeft: "auto",
      marginRight: "auto",
    },
  }));

  return (
    <div id="services-section">
      <Box sx={{ py: 10 }}>
        <Container>
          <CustomBox>
            <ImgContainer>
              <img src={houseCard} alt="house" style={{ maxWidth: "100%" }} />
            </ImgContainer>

            <Box>
              <div
                style={{
                  width: "20%",
                  height: "5px",
                  backgroundColor: "#10295e",
                  margin: "0 auto",
                }}
              ></div>
              <Typography
                sx={{
                  fontSize: "22px",
                  color: "#10295e",
                  fontWeight: "700",
                  my: 3,
                  textAlign: "center",
                }}
              >
                نسبة رضا عملائنا عن منتجنا هي (100%)
              </Typography>

              <Typography
                sx={{
                  fontSize: "20px",
                  color: "#000",
                  lineHeight: "27px",
                  textAlign: "center",
                }}
              >
                نحن هنا للتأكد من أن هذه ستكون تجربة لا تنسى لك
              </Typography>
            </Box>
          </CustomBox>

          {/* <Typography
            sx={{
              fontSize: "30px",
              color: "#004D56",
              fontWeight: "700",
              mt: 6,
              textAlign: "center",
            }}
          >
            تقييمات العملاء
            <p style={{ fontWeight: "bold", color: "#57919B" }}>7GREEN</p>
          </Typography>
          <Divider sx={{ margin: "auto", mt: 2 }} />

          <TextFlexbox>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <LargeText sx={{ color: "#004D56" }}>الأستاذ معتز</LargeText>
              <SmallText
                sx={{ color: "#67919B", textAlign: "center", width: "80%" }}
              >
                تمكنت من التخلص من أكثر من 20 كيلو غرام خلال أقل من 3 شهور من
                استخدام هذا المنتج انه رائع و أوصي به
              </SmallText>
            </Box>

            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <LargeText sx={{ color: "#004D56" }}>الأستاذة مها</LargeText>
              <SmallText
                sx={{ color: "#67919B", textAlign: "center", width: "80%" }}
              >
                أعجبني بشكل كبير أخيراً وصلت للمنتج المناسب الحمد لله
              </SmallText>
            </Box>

            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <LargeText sx={{ color: "#004D56" }}>السيدة فاطمة</LargeText>
              <SmallText
                sx={{ color: "#67919B", textAlign: "center", width: "80%" }}
              >
                كنت يائسة لأنني لم أجد المنتج الذي يساعدني في خفض وزني. بصراحة،
                فاجأني جدًا وأنصح كل النساء اللاتي يحتاجن لتخفيض وزنهن بسرعة أن
                يستخدمنه
              </SmallText>
            </Box>
          </TextFlexbox> */}
        </Container>
      </Box>
    </div>
  );
};

export default Details;
