import { Box, Container, styled, Typography } from "@mui/material";
import React from "react";
import House from "./House";
import { properties } from "../properties";

const Properties = () => {
  const PropertiesBox = styled(Box)(({ theme }) => ({
    display: "flex",
    justifyContent: "space-between",
    marginTop: theme.spacing(5),
    [theme.breakpoints.down("md")]: {
      flexDirection: "column",
      alignItems: "center",
    },
  }));

  const PropertiesTextBox = styled(Box)(({ theme }) => ({
    [theme.breakpoints.down("md")]: {
      textAlign: "center",
    },
  }));

  return (
    <div id="features-section">
      <Box sx={{ mt: 5, backgroundColor: "#7992c9", py: 10 }}>
        <Container>
          <PropertiesTextBox>
            <Typography
              sx={{
                color: "#fff",
                fontSize: "35px",
                fontWeight: "bold",
                textAlign: "end",
              }}
            >
              أهم النصائح الطبية
            </Typography>
            <Typography
              sx={{
                color: "#000",
                fontSize: "20px",
                mt: 1,
                textAlign: "end",
              }}
            >
              <p style={{ fontWeight: "bold", color: "#10295e" }}>Sunexmac </p>
              ينصح به أهم اطباء بحيث انه فعال مع مختلف أنواع الجسم, والأصابات
            </Typography>
          </PropertiesTextBox>

          <PropertiesBox>
            {properties.map((property) => (
              <House
                key={property.id}
                img={property.img}
                price={property.price}
                address={property.address}
                bedrooms={property.bedrooms}
                bathrooms={property.bathrooms}
                space={property.space}
              />
            ))}
          </PropertiesBox>
        </Container>
      </Box>
    </div>
  );
};

export default Properties;
