import { Box, Button, styled, Typography } from "@mui/material";
import { Container } from "@mui/system";
import React from "react";
import Navbar from "./Navbar";

import heroImg from "../media/hero_illustration.png";
import CustomButton from "./CustomButton";

const Hero = () => {
  const CustomBox = styled(Box)(({ theme }) => ({
    display: "flex",
    justifyContent: "center",
    gap: theme.spacing(5),
    marginTop: theme.spacing(3),
    [theme.breakpoints.down("md")]: {
      flexDirection: "column",
      alignItems: "center",
      textAlign: "center",
    },
  }));

  const Title = styled(Typography)(({ theme }) => ({
    fontSize: "64px",
    color: "#000336",
    fontWeight: "bold",
    margin: theme.spacing(4, 0, 4, 0),
    [theme.breakpoints.down("sm")]: {
      fontSize: "40px",
    },
  }));

  return (
    <Box sx={{ backgroundColor: "#779dd9", minHeight: "80vh" }}>
      <Container>
        <Navbar />
        <CustomBox>
          <Box sx={{ flex: "1.25" }}>
            <img
              src={heroImg}
              alt="heroImg"
              style={{
                maxWidth: "100%",
                marginBottom: "2rem",
                borderRadius: "40px",
              }}
            />
          </Box>
          <Box sx={{ flex: "1", textAlign: "right" }}>
            <Typography
              variant="body2"
              sx={{
                fontSize: "18px",
                color: "#000",
                fontWeight: "500",
                mt: 10,
                mb: 4,
                mr: 2,
              }}
            >
              <p>مرحبا بكم في</p>{" "}
              <p style={{ fontWeight: "bold", color: "#000" }}>
                مشد الركبة الحديث
              </p>
            </Typography>{" "}
            <Title variant="h1" sx={{ color: "#fff" }}>
              احمِ راحتك ونشاطك مع مشد الركبة المتطور الدعم الكامل للحركة
            </Title>{" "}
            <Typography
              variant="body2"
              sx={{ fontSize: "18px", color: "#08162b", my: 4 }}
            >
              لا تدع الألم يعيقك، استثمر في راحتك وأدائك مع مشد الركبة المتطور
              الذي يقدم الدعم الكامل لحركتك ويساعدك على الاستمتاع بحياة نشطة
              وخالية من القلق
            </Typography>
            {/* <CustomButton
              backgroundColor="#004D56"
              color="#fff"
              buttonText="المزيد عنا"
              heroBtn={true}
            /> */}
          </Box>
        </CustomBox>
      </Container>
    </Box>
  );
};

export default Hero;
