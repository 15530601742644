import { Box, Button, styled, Typography } from "@mui/material";
import React from "react";

import buyIcon from "../media/buy_icon.png";
import sellIcon from "../media/sell_icon.png";
import rentIcon from "../media/rent_icon.png";

import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import CustomButton from "./CustomButton";

const Guide = () => {
  const CustomBox = styled(Box)(({ theme }) => ({
    width: "30%",
    [theme.breakpoints.down("md")]: {
      width: "85%",
    },
  }));

  const GuidesBox = styled(Box)(({ theme }) => ({
    display: "flex",
    justifyContent: "space-around",
    width: "70%",
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(5),
    [theme.breakpoints.down("md")]: {
      width: "100%",
    },
    [theme.breakpoints.down("sm")]: {
      marginBottom: "0",
      flexDirection: "column",
    },
  }));

  const GuideBox = styled(Box)(({ theme }) => ({
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginTop: theme.spacing(5),
    [theme.breakpoints.down("sm")]: {
      margin: theme.spacing(2, 0, 2, 0),
    },
  }));

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div
        style={{
          width: "5%",
          height: "5px",
          backgroundColor: "#10295e",
          margin: "0 auto",
        }}
      ></div>

      <Typography
        variant="h3"
        sx={{ fontSize: "35px", fontWeight: "bold", color: "#10295e", my: 3 }}
      >
        كيف يعمل؟
      </Typography>

      <CustomBox>
        <Typography
          variant="body2"
          sx={{
            fontSize: "23px",
            fontWeight: "500",
            color: "#000",
            textAlign: "center",
          }}
        >
          احمِ راحتك ونشاطك مع مشد الركبة المتطور
        </Typography>
      </CustomBox>

      <GuidesBox>
        <GuideBox>
          <img
            src={buyIcon}
            alt="buyIcon"
            style={{ width: "223px", height: "223px" }}
          />
          <Typography
            variant="h4"
            sx={{
              fontWeight: "500",
              fontSize: "20px",
              color: "#3B3c45",
              my: 1,
              textAlign: "center",
              maxWidth: "60%",
            }}
          >
            <p style={{ fontWeight: "bold", color: "#57919B" }}>Sunexmac</p>
            الراحة والتحكم مشد الركبة المتطور يجلب لك أفضل النتائج. لا تتنازل عن
            راحتك أو أدائك. مشد الركبة المتطور يوفر لك التحكم الكامل ويسهم في
            تحقيق أقصى استفادة من نشاطك اليومي. استمتع بأداء مذهل دون أي قلق
          </Typography>
          <Box
            sx={{
              cursor: "pointer",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {/* <Typography
              variant="body2"
              sx={{ fontWeight: "bold", fontSize: "14px", color: "#0689FF" }}
            >
              How to buy
            </Typography>
            <ArrowRightAltIcon style={{ color: "#0689FF" }} /> */}
          </Box>
        </GuideBox>

        <GuideBox>
          <img
            src={rentIcon}
            alt="buyIcon"
            style={{ width: "223px", height: "223px" }}
          />
          <Typography
            variant="h4"
            sx={{
              fontWeight: "500",
              fontSize: "20px",
              color: "#3B3c45",
              my: 1,
              textAlign: "center",
              maxWidth: "60%",
            }}
          >
            <p style={{ fontWeight: "bold", color: "#57919B" }}>Sunexmac </p>
            تخلص من الألم والضغط اعتني بركبتيك مع مشد متطور. لا داعي للمعاناة من
            الألم والضغط على ركبتيك. مشد الركبة المتطور يمنحك الراحة والدعم
            اللازمين للتخلص من الألم والتركيز على أدائك بكامل طاقتك
          </Typography>
          <Box
            sx={{
              cursor: "pointer",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {/* <Typography
              variant="body2"
              sx={{ fontWeight: "bold", fontSize: "14px", color: "#0689FF" }}
            >
              How to rent
            </Typography>
            <ArrowRightAltIcon style={{ color: "#0689FF" }} /> */}
          </Box>
        </GuideBox>

        <GuideBox>
          <img
            src={sellIcon}
            alt="buyIcon"
            style={{ width: "223px", height: "223px" }}
          />
          <Typography
            variant="h4"
            sx={{
              fontWeight: "500",
              fontSize: "20px",
              color: "#3B3c45",
              my: 1,
              textAlign: "center",
              maxWidth: "60%",
            }}
          >
            <p style={{ fontWeight: "bold", color: "#57919B" }}>Sunexmac </p>
            تحسينات كبيرة في الراحة اكتشف مشد الركبة المتطور الذي سيحميك. تجربة
            الراحة تبدأ هنا! مشد الركبة المتطور سيوفر لك تحسينات كبيرة في الراحة
            ويحمي ركبتيك بشكل ممتاز، مما يسمح لك بالاستمتاع بنشاطاتك بدون أي قلق
          </Typography>
          <Box
            sx={{
              cursor: "pointer",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {/* <Typography
              variant="body2"
              sx={{ fontWeight: "bold", fontSize: "14px", color: "#0689FF" }}
            >
              How to sell
            </Typography>
            <ArrowRightAltIcon style={{ color: "#0689FF" }} /> */}
          </Box>
        </GuideBox>
      </GuidesBox>
      {/* 
      <CustomButton
        backgroundColor="#004D56"
        color="#fff"
        buttonText="See Full Guides"
        guideBtn={true}
      /> */}
    </Box>
  );
};

export default Guide;
